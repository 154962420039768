import { ApiError } from 'src/interface/errors/api-error';
import 'src/index.scss';

const renderLog = (message: string) => {
  if (!window['RENDER_PREAPP_DEBUG_TEXT']) return;

  const logElt = document.createElement('div');
  logElt.innerHTML = message;
  logElt.style.pointerEvents = 'none';
  logElt.style.zIndex = String(Math.pow(2, 52));
  document.body.appendChild(logElt);
};

renderLog('The index file was parsed');

async function init() {
  renderLog('Initialising function opened');

  try {
    const rootContainer = document.getElementById('root')!;

    if (rootContainer) {
      renderLog('Found root container');
    } else {
      renderLog('Could not find root container');
      return;
    }

    rootContainer.innerHTML = `
      <style>
        @keyframes rotate-infinity {
          from { stroke-dashoffset: 115 }
          to { stroke-dashoffset: 230 }
        }
        @keyframes fade-in {
          from { opacity: 0 }
          to { opacity: 1 }
        }
      </style>

      <div style="animation: fade-in 4s ease-in-out; padding: 32px; text-align: center">
        <h1 style="font-size: 24px">Initialising…</h1>
        <svg fill="none" viewBox="0 0 44 24" width="90" style="margin-top: 16px">
          <path style="
            --dist: 115;
            stroke-width: 6;
            stroke: #5c48f6;
            stroke-dasharray: 0 115 calc(115 / 4)
            calc((115 / 4) * 3) calc(115 / 4)
            calc((115 / 4) * 3);

            animation-name: rotate-infinity;
            animation-iteration-count:infinite;
            animation-duration: 1.3s;
            animation-timing-function: linear;
          " d="M18.364 5.636c4.5 4.5 2.772 8.228 7.272 12.728a9 9 0 1 0 0-12.728c-4.5 4.5-2.772 8.228-7.272 12.728a9 9 0 1 1 0-12.728Z" />
        </svg>
      </div>
    `;

    renderLog('Injected root container with loading DOM');

    const modules = Promise.all([
      import('react-dom/client'),
      import('src/screens/App'),
      import('./tools/check-outdated-browser'),
      import('./tools/window-size'),
    ]);

    renderLog('Began loading modules…');

    const response = await modules.catch(() => {
      renderLog('Loading modules failed');
    });

    if (!response) return;

    const [
      { createRoot },
      { default: App },
      { alertAboutOutdatedBrowsers },
      { updateWindowSizeStyles },
    ] = response;

    renderLog('Modules loaded.');

    window.global ??= window;
    renderLog('Ensured global object exists');

    alertAboutOutdatedBrowsers();
    renderLog('Alerted about outdated browsers');

    const startAppWithMockedNetwork = async () => {
      const { worker } = await import('./mocks/browser');

      await worker.start({
        onUnhandledRequest: 'bypass',
        serviceWorker: { url: '/mockServiceWorker.js' },
      });
    };
    renderLog('Added “start with mock network” function');

    // Workaround: Currently, we cannot run 2 service workers,
    // so we will not register for push notification when running "mocking mode"
    // After fully mock (even okta), we can change to default port to avoid the service worker collision
    // TODO: RTI-4005 find a way to merge the service workers / run two at once
    const mountApp = () => {
      // clear the loader
      rootContainer.innerHTML = '';

      const root = createRoot(rootContainer);
      root.render(<App />);
    };
    renderLog('Added “mount app” function');

    if (import.meta.env.VITE_MSW_ENABLED) {
      renderLog('Starting with mock network');
      startAppWithMockedNetwork().then(() => {
        renderLog('Mock network started');
        mountApp();
        renderLog('Mounted app');
      });
    } else {
      renderLog('Starting with all-real network');
      mountApp();
      renderLog('Mounted app');
    }

    updateWindowSizeStyles();
    renderLog('Updated window size styles');
  } catch (error) {
    renderLog(`
      <div style="padding: 32px">
        <p style="text-align: center; font-size: 24px; font-weight: bold">Something went wrong.</p>
        <p style="text-align: center">If this continues, contact support.</p>
        <hr style="border-top: 1px solid #798d8d; margin: 32px;">
        <p>Details:</p>
        ${
          error instanceof Error ?
            `<ul>
              ${error?.message ? `<li style="list-style: disc">${error.message}</li>` : ''}
              ${error?.stack ? `<li style="list-style: disc">${error.stack}</li>` : ''}
              ${error instanceof ApiError && error?.code ? `<li style="list-style: disc">${error.code}</li>` : ''}
            </ul>`
          : `<p>Error: ${String(error)}</p>`
        }
      </div>
      <hr>
    `);

    throw error;
  }
}

renderLog('The index file ran');

init();

renderLog('The index file ran to the end');
